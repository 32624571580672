@use '@angular/material'  as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "@ng-select/ng-select/themes/material.theme.css";
$assets_path : '../assets';
@import '../assets/styles/fonts';
@import '@angular/material/theming';

@font-face {
    font-family: 'lato light';
    // src: url('../assets/fonts/FontinSans/Fontin_Sans_R.otf') format('opentype');
}


$font-medium: $font-base;

$custom-typography: mat-typography-config(
    $font-family: $font-light,
    $headline: mat-typography-level(32px, 48px, 400),
    $body-1: mat-typography-level(16px, 24px, 500)
);
@include angular-material-typography($custom-typography);

.mat-toolbar.mat-primary {
    background: $topbar-bg;
    color: $topbar-fonts-color;
}
